import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Manorview Customer Story - Rotaready"
          description="Find out how Rotaready not only helped Manorview build more intelligent staff rotas, but save a six-figure sum on wage costs."
          url="customer-stories/hospitality-manorview"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Manorview"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    alt="Manorview logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Find out how Rotaready not only helped Manorview build more intelligent staff rotas, but save a six-figure sum on wage costs." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="9" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="380" />
              <StatSubtitle text="staff" />
            </StatItem>

            <StatItem>
              <StatHeadline text="5%" />
              <StatSubtitle text="saving on wage costs" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Manorview Hotels" />
              </Content>

              <Content>
                <TextBlock text="Established in 2007, Manorview Hotels & Leisure Group own 8 stunning hotels across Scotland. The group prides itself on quality and offers truly unique and memorable experiences to its customers. Part of making this happen is ensuring operational efficiency and having the right number of staff, in the right place, at the right time." />
              </Content>

              <Content>
                <TextBlock text="For almost 10 years Manorview scheduled its staff rotas and monitored time and attendance using Excel spreadsheets. But this manual approach left them with little clarity or control over costs, didn't account for varying demand, and required many man hours." />
              </Content>

              <Content>
                <TextBlock text="There was no way of building rotas tailored to specific departments, or visibility over projected wage spend. Rotas were never approved based on commercial factors like labour cost or wage percentage, instead based solely on the number of hours scheduled." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="A search for the perfect partner" />
              </Content>

              <Content>
                <TextBlock text="So in October 2016 the group began its search for a staff scheduling solution that would give the team greater clarity and control over cost and the ability to easily build and share intelligent staff rotas." />
              </Content>

              <Content>
                <TextBlock text="After reviewing a number of vendors, Manorview made the decision to implement Rotaready - which stood out on value for money, functionality and usability." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="From manual to automated staff scheduling" />
              </Content>

              <Content>
                <TextBlock text="Manorview opted for a staggered rollout, implementing Rotaready one site at a time to ensure a smooth transition from manual to automated staff scheduling and attendance tracking." />
              </Content>

              <Content>
                <TextBlock text="Part of this implementation included setting up bespoke rota templates for each of its 8 hotels and HQ. Having these templates in place saves the team at Manorview a huge amount of time when building staff rotas. They are now able to generate accurate rotas, in just one click, where previously it would have taken hours of back and forth. Not only is it quicker and easier to build and share rotas, they are now informed by future forecasts and real-time data, making them more accurate than ever." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Collage of Manorview hotel garden and food in the restaurant"
              />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="Being able to automatically build staff rotas that account for staff availability and future demand has made a world of difference to us. In fact it's meant we are able to better deploy almost 100 hours every week."
                  attribution="Carlton Graham, Operations Director"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="More than just staff scheduling" />
              </Content>

              <Content>
                <TextBlock text="For Manorview, implementing Rotaready wasn't just about making it easier to build staff rotas, it was about having greater control over costs. The business has always seen Rotaready as a wage management tool, as much as it has a staff scheduling solution." />
              </Content>

              <Content>
                <TextBlock text="Having visibility over costs at a more granular level, including costs per department and forecasted revenue, has been game changing for the business." />
              </Content>
            </Section>

            <Section>
              <PopQuote text="This combined with overtime approval plus automatic preparation of attendance data, has enabled Manorview to save 5% on wage costs - which equates to a six-figure sum per year." />
            </Section>

            <Section>
              <Content>
                <TextBlock text="Another feature the business has found to be particularly useful, is the ability to easily move employees and costs between sites, where necessary." />
              </Content>

              <Content>
                <Blockquote
                  text="I love the cross-site and group perspective of the product. It's clear Rotaready has been designed specifically for multi-site operators. It's so easy to move hours and costs between sites."
                  attribution="Carlton Graham, Operations Director"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="A streamlined approach" />
              </Content>

              <Content>
                <TextBlock text="Manorview have been a big adopter of Rotaready's free integrations. So far they've enabled two: one for their internal communications tool and another with their learning and development provider. Over the coming weeks this will become four, as they enable Rotaready's integrations with their two EPOS providers. Real- time revenue data will give Manorview even greater visibility and control over their labour costs, and it paves the way for Manorview to leverage Rotaready's intelligent sales prediction and demand forecasting functionality." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready has given us a tool to manage our payroll much more effectively. It also gives our team full visibility over holiday allowances and enables us to streamline weekly rotas much more efficiently. I would say we have saved at least 5% from our total wage bill as a result of the system."
                  attribution="Carlton Graham, Operations Director"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Hotel in winter with snow"
              />
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_manorview"
                label="rotaready.com/customer-stories/hospitality-manorview"
                postTo="marketing"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Manorview.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/header-manorview.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/logo-manorview.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/inline-manorview1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/inline-manorview2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
